import { Table, Tbody, Td, Th, Thead, Tr } from "@einride/ui"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Title2 } from "lib/ui/Typography/Typography"

interface PressRelease {
  id: string
  date: string
  slug: string
  title: string
}

interface Data {
  allContentfulPressRelease: {
    nodes: {
      id: string
      publishDate: string
      slug: string
      title: string
    }[]
  }
}

export const PressIndex = (): JSX.Element => {
  const { allContentfulPressRelease } = useStaticQuery<Data>(graphql`
    query {
      allContentfulPressRelease(
        filter: { hidden: { ne: true }, metaNoIndexNoFollow: { ne: true } }
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          id
          publishDate(formatString: "M.D.YYYY")
          slug
          title
        }
      }
    }
  `)

  const pressReleases: PressRelease[] = allContentfulPressRelease.nodes.map((pressRelease) => ({
    date: pressRelease.publishDate,
    id: pressRelease.id,
    slug: pressRelease.slug,
    title: pressRelease.title,
  }))

  const handleRowClick = (slug: string): void => {
    navigate(`/press/${slug}`)
  }

  return (
    <>
      <Title2>Press releases</Title2>
      <Table>
        <Thead>
          <Tr>
            <Th scope="col">Date</Th>
            <Th scope="col">Title</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pressReleases.map((row) => (
            <Tr key={row.id} onClick={() => handleRowClick(row.slug)}>
              <Td>{row.date}</Td>
              <Td>{row.title}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}

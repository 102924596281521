import { Icon, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { PressIndex } from "components/fragments/PressIndex/PressIndex"
import { ContentWrapper } from "components/shared/styles/Layout"
import { Layout } from "components/ui/Layout/Layout"
import { Link } from "components/ui/Link/Link"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { cvars } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"

export const PressView = (): JSX.Element => {
  return (
    <Layout>
      <ContentWrapper>
        <VerticalSpacing size="sm" />
        <Title1>Press Media Kit</Title1>
        <VerticalSpacing size="sm" />
        <MediaKitSection />
        <VerticalSpacing size="sm" />
        <Paragraph>Press contact: press@einride.tech</Paragraph>
        <VerticalSpacing size="xl" />
        <PressIndex />
        <VerticalSpacing size="xl" />
      </ContentWrapper>
    </Layout>
  )
}

interface Data {
  allContentfulMediaKit: {
    nodes: {
      id: string
      fileUrl: string
      title: string
      thumbnail: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
}

const MediaKitSection = (): JSX.Element => {
  const { allContentfulMediaKit } = useStaticQuery<Data>(graphql`
    query {
      allContentfulMediaKit(sort: { fields: createdAt, order: DESC }) {
        nodes {
          id
          fileUrl
          title
          thumbnail {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
    }
  `)
  return (
    <Grid>
      {allContentfulMediaKit.nodes.map((node) => (
        <ItemWrapper key={node.id}>
          {node.thumbnail.gatsbyImageData && (
            <ImageWrapper>
              <GatsbyImage image={node.thumbnail.gatsbyImageData} alt="" />
            </ImageWrapper>
          )}
          <Paragraph>{node.title}</Paragraph>
          <Link to={node.fileUrl}>
            <Paragraph>
              Download <Icon name="arrowRight" />
            </Paragraph>
          </Link>
        </ItemWrapper>
      ))}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${cvars.gridGutter};
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;
`

const ItemWrapper = styled.div`
  @media (max-width: 576px) {
    margin-bottom: 60px;
  }
`

import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { PressView } from "components/views/PressView/PressView"

interface PressProps {
  location: WindowLocation
}

const Press = ({ location }: PressProps): JSX.Element => {
  return (
    <>
      <Seo pathname={location.pathname} title="Press Releases" />
      <PressView />
    </>
  )
}

export default Press
